import { API_VERSION, URL_SAAS_API } from '../shared/common/constant';
import { getBearerToken } from './auth.service';

/* Microsoft SaaS Fulfillment APIs
RESOLVE
  - POST 
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions/resolve?api-version=<ApiVersion>
  - Header -> authorization, x-ms-marketplace-token
ACTIVATE
  - POST
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>/activate?api-version=<ApiVersion>
  - Header -> authorization
GET LIST OF ALL SUBSCRIPTIONS
  - GET
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions?api-version=<ApiVersion>
  - Header -> authorization
CHANGE PLAN
  - PATCH
  - https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>?api-version=<ApiVersion>
  - Header -> authorization
  - Payload -> {
  "planId": "gold" // the ID of the new plan to be purchased
}
  - Response Code -> 202
CANCEL SUBSCRIPTION
 - DELETE
 - https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>?api-version=<ApiVersion>
 - Response Code -> 202
  */

/* Resolve subscription 
POST
https://marketplaceapi.microsoft.com/api/saas/subscriptions/resolve?api-version=${API_VERSION}
*/

// const API_ENDPOINT = URL_SAAS_API;
const API_ENDPOINT = URL_SAAS_API;

export async function resolveSubscription(marketplaceToken, accessToken) {
  // TODO: Remove sample marketplaceToken
  // const marketplaceToken = `ewogICJpZCI6ICIyODExZTc0Yi1jMmJkLTQ2YWQtYmM0Zi05MDQ4NDViN2MxZTEiLAogICJuYW1lIjogInRlc3QgZ29sZCIsCiAgIm9mZmVySWQiOiAicGVyLXNlYXQiLAogICJwbGFuSWQiOiAicGVyLXNlYXQtMyIsCiAgImJlbmVmaWNpYXJ5IjogewogICAgImVtYWlsSWQiOiAidXNlckBmb3J0aGNvZmZlZS5jb20iLAogICAgIm9iamVjdElkIjogIjQ2OTE0YWQ0LWYzMmMtNGYwZi05Y2ZjLTlhZmU5MjNlZDBkZSIsCiAgICAidGVuYW50SWQiOiAiZWVhYmY4MWEtMmNmYS00ZmJlLWJmMzgtZDM2ZmNlMzc5MjBkIgogIH0sCiAgInB1cmNoYXNlciI6IHsKICAgICJlbWFpbElkIjogInVzZXJAZm9ydGhjb2ZmZWUuY29tIiwKICAgICJvYmplY3RJZCI6ICI0NjkxNGFkNC1mMzJjLTRmMGYtOWNmYy05YWZlOTIzZWQwZGUiLAogICAgInRlbmFudElkIjogImVlYWJmODFhLTJjZmEtNGZiZS1iZjM4LWQzNmZjZTM3OTIwZCIKICB9LAogICJxdWFudGl0eSI6IG51bGwsCiAgImF1dG9SZW5ldyI6IGZhbHNlLAogICJpc1Rlc3QiOiBmYWxzZSwKICAiaXNGcmVlVHJpYWwiOiBmYWxzZQp9`;
  // TODO: Replace API
  // localhost:3978 -> where the emulator port is running
  console.log(accessToken);

  if (!accessToken) {
    accessToken = await getBearerToken();
  }
  console.log(accessToken);
  const resolveResponse = await fetch(
    `${API_ENDPOINT}/api/saas/subscriptions/resolve?api-version=${API_VERSION}`,
    {
      method: 'POST',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json',
        'x-ms-marketplace-token': `${marketplaceToken}`,
      },
    }
  );
  if (resolveResponse.ok) {
    console.log('Subscription resolved');
  } else {
    console.error(
      'Error resolving subscription:',
      await resolveResponse.json()
    );
  }
  return await resolveResponse.json();
}

/* Activate subscription 
POST  
https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>/activate?api-version=<ApiVersion>
*/
export async function activateSubscription(subscriptionId, planId) {
  console.log('ACTIVATING SUBSCRIPTION');
  const accessToken = await getBearerToken();
  const activateResponse = await fetch(
    `${API_ENDPOINT}/api/saas/subscriptions/${subscriptionId}/activate?api-version=${API_VERSION}`,
    {
      method: 'POST',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planId: planId,
      }),
    }
  );

  if (activateResponse.ok) {
    console.log('Subscription activated');
  } else {
    console.error(
      'Error activating subscription:',
      await activateResponse.json()
    );
  }
  return null;
}

/* List available subscriptions */
export async function listAvailableSubscription(accessToken) {
  console.log('listing all subscription');
  const availableSubsResponse = await fetch(
    `https://dev-teams-svc.vodium.com/subscriptions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      },
    }
  );
  const response = await availableSubsResponse.json();
  return response;
}
//TO DO: Remove
const SUBSCRIPTION_ID = '4ef72d54-0562-42bd-8c3d-abf252c545a9';

/* List available plans
GET
https://marketplaceapi.microsoft.com/api/saas/subscriptions/<subscriptionId>/listAvailablePlans?api-version=<ApiVersion>&planId=<planId>
 */
export async function listAvailablePlan(SUBSCRIPTION_ID) {
  const accessToken = await getBearerToken();
  console.log(`${accessToken}`);
  const availablePlansResponse = await fetch(
    `${API_ENDPOINT}/api/saas/subscriptions/${SUBSCRIPTION_ID}/listAvailablePlans?api-version=${API_VERSION}`,
    {
      method: 'GET',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json',
      },
    }
  );
  const response = await availablePlansResponse.json();
  return response;
}

/* Change plan 
PATCH
https://marketplaceapi.microsoft.com/api/saas/subscriptions/${SUBSCRIPTION_ID}?api-version=${API_VERSION}
*/
export async function changePlan(planId, SUBSCRIPTION_ID) {
  const accessToken = await getBearerToken();
  const changeResponse = await fetch(
    `${API_ENDPOINT}/api/saas/subscriptions/${SUBSCRIPTION_ID}?&api-version=${API_VERSION}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planId: planId,
        // Qty: Qty,
      }),
    }
  );
  if (changeResponse.ok) {
    console.log('Subscription changed');
  } else {
    console.error('Error changing subscription:', await changeResponse.json());
  }
  return null;
}

/* Cancel subscription 
DELETE
https://marketplaceapi.microsoft.com/api/saas/subscriptions/${SUBSCRIPTION_ID}?api-version=${API_VERSION}
*/
export async function cancelSubscriptionHandler(subscriptionId) {
  const accessToken = await getBearerToken();

  const url = `${API_ENDPOINT}/api/saas/subscriptions/${subscriptionId}?api-version=${API_VERSION}`;
  const cancelResponse = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });

  if (!cancelResponse.ok) {
    const errorMessage = await cancelResponse.text();
    throw new Error(`Failed to cancel subscription: ${errorMessage}`);
  }

  const contentType = cancelResponse.headers.get('Content-Type');
  if (contentType && contentType.includes('application/json')) {
    return cancelResponse.json();
  } else {
    return cancelResponse.text();
  }
}
