import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toggleMainContent } from '../services/app.service';
import './Sidebar.css';
import { getCurrentUserRole } from '../services/msGraphApiCall';
import { loginRequest } from '../services/authProvider';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {
  AppDefaults,
  CurrentRole,
  sidebarResponsiveWidth,
} from '../shared/common/constant';

const Sidebar = () => {
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [isLabelVisible, setIsLabelVisible] = React.useState(false);
  const [role, setRole] = useState();
  const { instance, inProgress } = useMsal();
  const normalLink =
    'mb-2 transition-all duration-200 hover:bg-neutral-600 group';
  const activeLink =
    'mb-2 font-semibold transition-all duration-200 bg-neutral-600 group';

  function toggleSidebar() {
    const sidebar = document.getElementById('mySidebar');
    sidebar.classList.toggle('minimized');
    toggleMainContent();
    setIsMinimized(!isMinimized);
  }

  const hideLicenseManagement = async (response) => {
    const role = response.value;
    for (let x = 0; x < role.length; x++) {
      if (
        role[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        role[x].appRoleId === CurrentRole.ADMIN
      ) {
        console.log(role);
        console.log('current admin');
        //setHideLicense(false)
        document.getElementById('licenseManagement').style.display = 'block';
      } else if (
        role[x].resourceDisplayName === AppDefaults.ResourceDisplayName &&
        role[x].appRoleId === CurrentRole.USER
      ) {
        console.log('current user');
        //setHideLicense(true)
        document.getElementById('licenseManagement').style.display = 'none';
      }
    }
  };

  React.useEffect(() => {
    if (!isMinimized) {
      // Set a delay to show the label after 0.5 seconds
      const delay = setTimeout(() => {
        setIsLabelVisible(true);
      }, 200);
      return () => {
        clearTimeout(delay);
      };
    } else {
      setIsLabelVisible(false);
    }
  }, [isMinimized]);

  useEffect(() => {
    if (!role && inProgress === InteractionStatus.None) {
      getCurrentUserRole()
        .then((response) => {
          setRole(response);
          hideLicenseManagement(response);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  }, [role, inProgress, instance]);

  return (
    <div
      id='mySidebar'
      className={`sidebar transition-all duration-500 w-60 -left-${sidebarResponsiveWidth} z-10 bg-zinc-800 xl:w-64 xl:flex-col fixed xl:inset-y-0 xl:-left-0`}
    >
      <div className='flex flex-col pt-10 overflow-y-auto overflow-x-hidden h-screen'>
        <div className='flex flex-col justify-between flex-1 h-dvh mt-auto pt-14'>
          <div className='space-y-4 mb-10'>
            <nav className='flex-1 space-y-0'>
              <NavLink
                to='/home'
                className={({ isActive }) => {
                  return isActive ? activeLink : normalLink;
                }}
              >
                <nav className='menu-item py-2' title='Home'>
                  <svg
                    className='side-bar-icons'
                    width='30'
                    height='30'
                    viewBox='0 2 16 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1.25 7.5L2.75 6M2.75 6L8 0.75L13.25 6M2.75 6V13.5C2.75 13.9142 3.08579 14.25 3.5 14.25H5.75M13.25 6L14.75 7.5M13.25 6V13.5C13.25 13.9142 12.9142 14.25 12.5 14.25H10.25M5.75 14.25C6.16421 14.25 6.5 13.9142 6.5 13.5V10.5C6.5 10.0858 6.83579 9.75 7.25 9.75H8.75C9.16421 9.75 9.5 10.0858 9.5 10.5V13.5C9.5 13.9142 9.83579 14.25 10.25 14.25M5.75 14.25H10.25'
                      stroke='#fff'
                      strokeWidth='1.2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  {isLabelVisible && <span className='label'>Home</span>}
                </nav>
              </NavLink>
              <NavLink
                to='/subscriptions'
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <nav className='menu-item py-2' title='Subsciptions'>
                  <svg
                    className='side-bar-icons'
                    width='30'
                    height='30'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    stroke='#fff'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M18.18 8.03933L18.6435 7.57589C19.4113 6.80804 20.6563 6.80804 21.4241 7.57589C22.192 8.34374 22.192 9.58868 21.4241 10.3565L20.9607 10.82M18.18 8.03933C18.18 8.03933 18.238 9.02414 19.1069 9.89309C19.9759 10.762 20.9607 10.82 20.9607 10.82M18.18 8.03933L13.9194 12.2999C13.6308 12.5885 13.4865 12.7328 13.3624 12.8919C13.2161 13.0796 13.0906 13.2827 12.9882 13.4975C12.9014 13.6797 12.8368 13.8732 12.7078 14.2604L12.2946 15.5L12.1609 15.901M20.9607 10.82L16.7001 15.0806C16.4115 15.3692 16.2672 15.5135 16.1081 15.6376C15.9204 15.7839 15.7173 15.9094 15.5025 16.0118C15.3203 16.0986 15.1268 16.1632 14.7396 16.2922L13.5 16.7054L13.099 16.8391M13.099 16.8391L12.6979 16.9728C12.5074 17.0363 12.2973 16.9867 12.1553 16.8447C12.0133 16.7027 11.9637 16.4926 12.0272 16.3021L12.1609 15.901M13.099 16.8391L12.1609 15.901'
                        stroke='#fff'
                        strokeWidth='1.5'
                      ></path>{' '}
                      <path
                        d='M8 13H10.5'
                        stroke='#fff'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M8 9H14.5'
                        stroke='#fff'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M8 17H9.5'
                        stroke='#fff'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M19.8284 3.17157C18.6569 2 16.7712 2 13 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3 4.34315 3 6.22876 3 10V14C3 17.7712 3 19.6569 4.17157 20.8284C5.34315 22 7.22876 22 11 22H13C16.7712 22 18.6569 22 19.8284 20.8284C20.7715 19.8853 20.9554 18.4796 20.9913 16'
                        stroke='#fff'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                    </g>
                  </svg>
                  {isLabelVisible && (
                    <span className='label'>Subscriptions</span>
                  )}
                </nav>
              </NavLink>
              <NavLink
                to='/licenses'
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                id='licenseManagement'
                style={{ display: 'none' }}
              >
                <nav className='menu-item py-2' title='License Management'>
                  <svg
                    fill='#fff'
                    className='side-bar-icons'
                    width='30'
                    height='30'
                    viewBox='0 -64 640 640'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                    <g
                      id='SVGRepo_tracerCarrier'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <g id='SVGRepo_iconCarrier'>
                      <path d='M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z' />
                    </g>
                  </svg>
                  {isLabelVisible && (
                    <span className='label'>User Management</span>
                  )}
                </nav>
              </NavLink>
              {isLabelVisible && (
                <div className='pt-0'>
                  <p className='label sidebar-divider'> Resource Center </p>
                </div>
              )}
              <NavLink
                to='/help'
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <nav className='menu-item' title='Help'>
                  <svg
                    className='side-bar-icons'
                    width='30'
                    height='30'
                    viewBox='0 3 18 12'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='#fff'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                    <g
                      id='SVGRepo_tracerCarrier'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <g id='SVGRepo_iconCarrier'>
                      <path
                        d='M8.5 0c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.813-8.5-8.5-8.5zM8.5 16c-4.136 0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5 3.364 7.5 7.5-3.364 7.5-7.5 7.5zM9.658 12.219c0 0.568-0.462 1.031-1.031 1.031-0.571 0-1.033-0.463-1.033-1.031 0-0.57 0.462-1.032 1.033-1.032 0.569 0 1.031 0.461 1.031 1.032zM10.662 4.215c0.448 0.565 0.674 1.328 0.55 1.855-0.243 1.027-0.842 1.567-1.371 2.043-0.543 0.489-0.934 0.84-0.934 1.647h-1c0-1.251 0.671-1.856 1.264-2.39 0.461-0.415 0.896-0.807 1.066-1.529 0.034-0.143-0.039-0.6-0.36-1.005-0.307-0.389-0.728-0.586-1.248-0.586-1.779 0-1.869 1.444-1.873 1.609l-1-0.027c0.024-0.893 0.655-2.582 2.873-2.582 0.818 0 1.539 0.343 2.033 0.965z'
                        fill='#fff'
                      />
                    </g>
                  </svg>
                  {isLabelVisible && <span className='label'>Help</span>}
                </nav>
              </NavLink>
              <NavLink
                to='/contact'
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <nav className='menu-item' title='Contact Us'>
                  <svg
                    className='side-bar-icons'
                    width='30'
                    height='30'
                    viewBox='28 0 15 59'
                    xmlns='http://www.w3.org/2000/svg'
                    strokeWidth='4'
                    stroke='#fff'
                    fill='none'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                    <g
                      id='SVGRepo_tracerCarrier'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <g id='SVGRepo_iconCarrier'>
                      <path
                        d='M12.91,31.8V26.1a19.09,19.09,0,0,1,38.18,0v5.7'
                        strokeLinecap='round'
                      />
                      <path
                        d='M12.06,31.8h4.7a0,0,0,0,1,0,0V45.18a0,0,0,0,1,0,0h-4.7a3,3,0,0,1-3-3V34.8A3,3,0,0,1,12.06,31.8Z'
                        strokeLinecap='round'
                      />
                      <path
                        d='M50.24,31.8h4.7a0,0,0,0,1,0,0V45.18a0,0,0,0,1,0,0h-4.7a3,3,0,0,1-3-3V34.8A3,3,0,0,1,50.24,31.8Z'
                        transform='translate(102.18 76.98) rotate(180)'
                        strokeLinecap='round'
                      />
                      <path
                        d='M51.7,45.56v5a4,4,0,0,1-4,4H36.56'
                        strokeLinecap='round'
                      />
                      <rect
                        x='28.45'
                        y='51.92'
                        width='8.1'
                        height='5.07'
                        rx='2'
                        strokeLinecap='round'
                      />
                    </g>
                  </svg>
                  {isLabelVisible && <span className='label'>Contact Us</span>}
                </nav>
              </NavLink>
              <div className='flex items-center justify-end'>
                <button
                  className='icon-button menu-item'
                  onClick={toggleSidebar}
                  title={isMinimized ? 'Open' : 'Close'}
                >
                  {isMinimized && (
                    <svg
                      className='side-bar-icon'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                      <g
                        id='SVGRepo_tracerCarrier'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <g id='SVGRepo_iconCarrier'>
                        <path
                          d='M5.5 5L11.7929 11.2929C12.1834 11.6834 12.1834 12.3166 11.7929 12.7071L5.5 19'
                          stroke='#fff'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M13.5 5L19.7929 11.2929C20.1834 11.6834 20.1834 12.3166 19.7929 12.7071L13.5 19'
                          stroke='#fff'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                  )}
                  {!isMinimized && (
                    <svg
                      className='side-bar-icon'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g id='SVGRepo_bgCarrier' strokeWidth='0' />
                      <g
                        id='SVGRepo_tracerCarrier'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <g id='SVGRepo_iconCarrier'>
                        <path
                          d='M19 19L12.7071 12.7071C12.3166 12.3166 12.3166 11.6834 12.7071 11.2929L19 5'
                          stroke='#fff'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M11 19L4.70711 12.7071C4.31658 12.3166 4.31658 11.6834 4.70711 11.2929L11 5'
                          stroke='#fff'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
