import { msalInstance } from '..';
import { loginRequest } from '../services/authProvider';

const logout = () => {
  msalInstance.logoutRedirect();
  console.log('User logged out.');
};

const login = async () => {
  await msalInstance.handleRedirectPromise();
  await msalInstance.loginRedirect(loginRequest);
  console.log('User logged in.');
};

const VODIUM_BACKEND_DEV_URL = 'dev-teams-svc.vodium.com';
const VODIUM_BACKEND_URL = 'teams-svc.vodium.com';

const getBearerToken = async () => {
  const getBearerTokenResponse = await fetch(
    `https://${VODIUM_BACKEND_DEV_URL}/auth/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': 'no-cors',
        Authorization: '',
      },
    }
  );
  const responseData = await getBearerTokenResponse.json();
  const accessToken = responseData.access_token;
  return `Bearer ${accessToken}`;
};

export { logout, login, getBearerToken };
